<template>
    <div class="friendImg">
        <!-- 背景图 -->
<!--        <img class="headBack" :src="img" alt="">-->
        <div class="head_title">
            <span class="blog_title">{{text}}</span><br/><br/><br/>
            <ul>
                <li>
                    <span>{{blogtype===0?'原创':'转载'}}</span>
                </li>
                <li>
                    <svg class="icon" aria-hidden="true">
                         <use xlink:href="#icon-yonghu"></use>
                    </svg>
                    <span>FQZ</span>
                </li>
                <li>
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-rili2"></use>
                    </svg>
                    <span>{{times}}</span>
                </li>
                <li>
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-liulan1"></use>
                    </svg>
                    <span>{{views}}</span>
                </li>
                <li>
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-xiaoxi2"></use>
                    </svg>
                    <span>{{comments}}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "blogpostImg",
        data(){
            return{
                /* 博文标题 */
                text:'',
                /* 博文副标题 */
                blogViceTitle:'',
                /* 图片地址 */
                img:"",
                /* 时间 */
                times:'',
                /* 浏览数 */
                views:0,
                /* 评论量 */
                comments:0,
                /* 标签 */
                label:"",
                /* 原创还是转载 */
                blogtype:0,
                /* 博文id */
                conedit:{
                    id:this.$route.params.id
                }
            }
        },
        methods:{
            async findBlogId(){
                const res = await this.$home.findBlogByid(this.conedit)
                this.text = res.data.blogTitle
                this.blogViceTitle = res.data.blogViceTitle
                this.img = res.data.blogPhoto
                this.times = res.data.releaseDt
                this.views = res.data.views
                this.comments = res.data.comment
                this.blogtype = res.data.isReprint
            }
        },
        created() {
            this.findBlogId()
            console.log(this.blogtype)
        }
    }
</script>

<style scoped>

</style>
